/**
 * タブ切り替え
 * @constructor
 */
 class Tab {
  constructor() {
  }
  
  /**
   * スマホのナビメニュー
   */
  init() {
    //タブ切り替え
    $(function() {
      //クリックしたときのファンクションをまとめて指定
      $('.js-tabTrigger').on("click", function() {
        //.index()を使いクリックされたタブが何番目かを調べ、
        //indexという変数に代入します。
        var index = $('.js-tabTrigger').index(this);

        //コンテンツを一度すべて非表示にし、
        $('.js-tabTarget').css('display','none');

        //クリックされたタブと同じ順番のコンテンツを表示します。
        $('.js-tabTarget').eq(index).fadeIn();

        //タブについているクラスis-currentを消し、
        $('.js-tabTrigger').removeClass('is-current');

        //クリックされたタブのみにクラスis-currentをつけます。
        $(this).addClass('is-current')
      });
    });

    //タブ切り替えダイレクトリンク
    $(function() {
      //location.search#以下を取得 変数paramに格納
      var param = location.search.substring(1);

      //paramの中に#tab～が存在するか調べる。
      param = (param.match(/tabIndex\d+$/) || [])[0];

      //paramに要素が存在する場合、paramで取得した文字列（#tab2,#tab3等）から#より後を取得(tab2,tab3)	
      if($('.js-' + param).length){
        var tabname = param;
      } else{
        // 要素が存在しなければtabnameにtab1を代入する
        var tabname = "tabIndex1";
      }
      //コンテンツを一度すべて非表示にし、
      $('.js-tabTarget').css('display','none');

      //一度タブについているクラスis-currentを消し、
      $('.js-tabTrigger').removeClass('is-current');

      var tabno = $('.js-tabTrigger.js-' + tabname).index();

      //クリックされたタブと同じ順番のコンテンツを表示します。
      $('.js-tabTarget').eq(tabno).fadeIn();

      //クリックされたタブのみにクラスis-currentをつけます。
      $('.js-tabTrigger').eq(tabno).addClass('is-current');

      //タブがスクロールする際にカレントのタブの位置まで移動します。
      setTimeout(function(){
        $('.m-proDetailTabOuter .simplebar-content-wrapper').animate({
          scrollLeft: $('.js-tabTrigger').eq(tabno).offset().left - $('.m-proDetailTabOuter').offset().left
        }, 300, 'swing');
      },200);
    });
  }
};

export default Tab;
