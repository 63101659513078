/**
 * タブ切り替え
 * @constructor
 */
class canvasWave {
    constructor() {
        this.unit = 100;
        this.canvasList = []; // キャンバスの配列
        this.info = {}; // 全キャンバス共通の描画情報
        this.info.seconds = 0;
        this.info.t = 0;
        this.colorList = []; // 各キャンバスの色情報
        this.waveSeconds = .012; // 波の移動の早さ（数値が高いほど波が早い）
        // this.waveHeight = 3; // 波自体の高さ（数値が低いほど波が高い）※canvas自体との高さを考慮する必要あり
        this.canvasHeight = 200; // canvas自体の高さ
    }

    init() {
        if (document.getElementById("waveCanvas") != null) {
            this.canvasWaves();
        }
    };
    canvasWaves() {
        let that = this; // class canvasWave を変数thatに代入

        // canvas1個めの色指定
        this.canvasList.push(document.getElementById("waveCanvas"));
        this.colorList.push(['#0ff', '#ff0', '#f00', '#00f', '#f0f']); // 重ねる波線の色設定
        // canvas2個めの色指定（必要なら）
        // this.canvasList.push(document.getElementById("waveCanvas2"));
        // this.colorList.push(['#43c0e4']);

        // 各キャンバスの初期化
		for(let canvasIndex in this.canvasList) {
            let canvas = this.canvasList[canvasIndex]; // キャンバスのIDからDOMを指定して変数canvasに代入
            canvas.width = document.documentElement.clientWidth; // Canvasのwidthをウィンドウの幅に合わせる
            canvas.height = this.canvasHeight; // キャンバスの高さ
            /* ▼ canvasにプロパティ'contextCache'を追加して、メソッドgetContext()で2Dオブジェクトを代入 */
            canvas.contextCache = canvas.getContext("2d");
        }

        setInterval(this.update, 35, that);
    };
    update(obj) {
		for(let canvasIndex in obj.canvasList) {
            let canvas = obj.canvasList[canvasIndex];
            // 各キャンバスの描画
            obj.draw(canvas, obj.colorList[canvasIndex]);
        }
        // 共通の描画情報の更新
        obj.info.seconds += obj.waveSeconds;
        obj.info.t = obj.info.seconds * Math.PI;
        // 自身の再起呼び出し
        // setTimeout内でのthisの参照先が変更される為、再起関数を使えず...
        // setTimeout(this.update, 35);
    }

    /**
    * アニメーション用ファンクション
    */
    draw(canvas, color) {
		// 対象のcanvasのコンテキストを取得
        var context = canvas.contextCache;
        // キャンバスの描画を.clearRect()でクリア
        context.clearRect(0, 0, canvas.width, canvas.height);

        /* 波を描画 
            drawWave(canvas, color[数字（波の数を0から数えて指定）], 透過, 波の幅のzoom,波の開始位置の遅れ, 波自体の高さ（数値が低いほど波が高い） )
            ここもfor文で回してしまった方が良いか考え中...
            波の幅の数値を、どれくらいの範囲の乱数にするべきなのかが決めかねる感じ
        */
        this.drawWave(canvas, color[0], 0.8, 3, 0, 2.5);
        this.drawWave(canvas, color[1], 0.5, 4, 50, 3);
        this.drawWave(canvas, color[2], 0.3, 1.6, 20, 3);
        this.drawWave(canvas, color[3], 0.2, 3, 100, 2.2);
        this.drawWave(canvas, color[4], 0.8, 2, 80, 1.9);
    }

    /**
    * 波を描画
    * drawWave(色, 不透明度, 波の幅のzoom, 波の開始位置の遅れ)
    */
    drawWave(canvas, color, alpha, zoom, delay, waveHeight) {
        let context = canvas.contextCache;
        context.strokeStyle = color; // 線の色
        context.lineWidth = 1; // 線の幅
        context.globalAlpha = alpha;
        context.beginPath(); // パスの開始
        this.drawSine(canvas, this.info.t / 0.5, zoom, delay, waveHeight);
        context.stroke(); // 線
    }

    drawSine(canvas, t, zoom, delay, waveHeight) {
        let xAxis = Math.floor(canvas.height / 2),
            yAxis = 0,
            context = canvas.contextCache,
            x = t, // 時間を横の位置とする
            y = Math.sin(x)/zoom;
        context.moveTo(yAxis, this.unit * y + xAxis); // スタート位置にパスを置く
    
        // Loop to draw segments (横幅の分、波を描画)
        for (let i = yAxis; i <= canvas.width + 10; i += 10) {
            x = t+(-yAxis+i)/this.unit/zoom;
            // y = Math.sin(x - delay)/this.waveHeight;
            y = Math.sin(x - delay)/waveHeight;
            context.lineTo(i, this.unit*y+xAxis);
        }
    }
};

export default canvasWave;
