/**
 * タブ切り替え
 * @constructor
 */
class recruitAction {
    constructor() {

    }

    init() {
        this.interview__list();
    }

    interview__list() {
        let $elem = $('.r-topInterview__listItem'),
            $elem2 = $('.r-topInterview__seniorListItem');

        [$elem, $elem2].forEach($ele=>{
            $ele.mouseover(e => {
                let itemNum = $(e.currentTarget).index();
                $elem.eq(itemNum).addClass('is-active');
                $elem2.eq(itemNum).addClass('is-active');
            }).mouseout(() => {
                $elem.removeClass('is-active');
                $elem2.removeClass('is-active');
            });
        });
    }

};

export default recruitAction;
