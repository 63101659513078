import Vue from 'vue';

/**
 * タブ切り替え
 * @constructor
 */
class recruitHeaderNavi {
    constructor() {

    }

    /**
     * スマホのナビメニュー
     */
    init() {
        this.setVue();
    }
    setVue() {
        this.app = new Vue({
            el:'#apps',
            data: function() {
                return {
                    isActive: false,
                    isActive2: false,
                }
            },
            // methods: {
            //     btnClick(){
            //         console.log('click');
            //     }
            // }
        })
    }
};

export default recruitHeaderNavi;
