/**
 * スライダークラス
 * @constructor
 */

import Swiper from 'swiper';
class Slider {
  constructor() {
  }
  
  /**
   * スライダー
   */
  init() {
    let topKvSlider = new Swiper ('.js-topKvSlider', {
      autoplay: {
        delay: 5000,
      },
      effect: 'fade',
      speed: 0,
      pagination: { 
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true,
      },
    })

    // 商品詳細スライダー
    // let proDetailSliderThumb = function(num) {
    //   new Swiper ('.js-proDetailSliderThumb' + num, {
    //     loop: true,
    //     slidesPerView: 3,
    //     freeMode: true,
    //     watchSlidesProgress: true,
    //   });
    // }

    // let proDetailSlider = function(num) {
    //   var thumbName = 'proDetailSliderThumb' + num;
    //   new Swiper ('.js-proDetailSlider' + num, {
    //     loop: true,
    //     navigation: {
    //       nextEl: ".swiper-button-next",
    //       prevEl: ".swiper-button-prev",
    //     },
    //     thumbs: {
    //       swiper: thumbName
    //     },
    //   });
    // }
    // let proDetailSliderThumb01 = proDetailSliderThumb('01');
    // let proDetailSlider01 =  proDetailSlider('01');

    // 商品詳細スライダー01
    let proDetailSliderThumb01 = new Swiper ('.js-proDetailSliderThumb01', {
      freeMode: true,
      watchSlidesProgress: true,
    });
    let proDetailSlider01 = new Swiper ('.js-proDetailSlider01', {
      zoom: true,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      thumbs: {
        swiper: proDetailSliderThumb01
      },
    });

    // 商品詳細スライダー02
    let proDetailSliderThumb02 = new Swiper ('.js-proDetailSliderThumb02', {
      freeMode: true,
      watchSlidesProgress: true,
    });
    let proDetailSlider02 = new Swiper ('.js-proDetailSlider02', {
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      thumbs: {
        swiper: proDetailSliderThumb02
      },
    });

    // 商品詳細スライダー03
    let proDetailSliderThumb03 = new Swiper ('.js-proDetailSliderThumb03', {
      freeMode: true,
      watchSlidesProgress: true,
    });
    let proDetailSlider03 = new Swiper ('.js-proDetailSlider03', {
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      thumbs: {
        swiper: proDetailSliderThumb03
      },
    });

    // 商品詳細スライダー04
    let proDetailSliderThumb04 = new Swiper ('.js-proDetailSliderThumb04', {
      freeMode: true,
      watchSlidesProgress: true,
    });
    let proDetailSlider04 = new Swiper ('.js-proDetailSlider04', {
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      thumbs: {
        swiper: proDetailSliderThumb04
      },
    });

    // 商品詳細スライダー05
    let proDetailSliderThumb05 = new Swiper ('.js-proDetailSliderThumb05', {
      freeMode: true,
      watchSlidesProgress: true,
    });
    let proDetailSlider05 = new Swiper ('.js-proDetailSlider05', {
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      thumbs: {
        swiper: proDetailSliderThumb05
      },
    });

    // 商品詳細スライダー06
    let proDetailSliderThumb06 = new Swiper ('.js-proDetailSliderThumb06', {
      freeMode: true,
      watchSlidesProgress: true,
    });
    let proDetailSlider06 = new Swiper ('.js-proDetailSlider06', {
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      thumbs: {
        swiper: proDetailSliderThumb06
      },
    });

    // 商品詳細スライダー07
    let proDetailSliderThumb07 = new Swiper ('.js-proDetailSliderThumb07', {
      freeMode: true,
      watchSlidesProgress: true,
    });
    let proDetailSlider07 = new Swiper ('.js-proDetailSlider07', {
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      thumbs: {
        swiper: proDetailSliderThumb07
      },
    });

    // 商品詳細スライダー08
    let proDetailSliderThumb08 = new Swiper ('.js-proDetailSliderThumb08', {
      freeMode: true,
      watchSlidesProgress: true,
    });
    let proDetailSlider08 = new Swiper ('.js-proDetailSlider08', {
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      thumbs: {
        swiper: proDetailSliderThumb08
      },
    });
    
    // 商品詳細スライダー09
    let proDetailSliderThumb09 = new Swiper ('.js-proDetailSliderThumb09', {
      freeMode: true,
      watchSlidesProgress: true,
    });
    let proDetailSlider09 = new Swiper ('.js-proDetailSlider09', {
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      thumbs: {
        swiper: proDetailSliderThumb09
      },
    });

    // 商品詳細スライダー10
    let proDetailSliderThumb10 = new Swiper ('.js-proDetailSliderThumb10', {
      freeMode: true,
      watchSlidesProgress: true,
    });
    let proDetailSlider10 = new Swiper ('.js-proDetailSlider10', {
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      thumbs: {
        swiper: proDetailSliderThumb10
      },
    });
  }
};

export default Slider;
