/**
 * MOREボタン
 * @constructor
 */
  class MoreBtn {
    constructor() {
  }
  
  init() {

    let show = 10; //最初に表示する件数
    let num = 10;  //clickごとに表示したい件数
    let contents = '.m-moreList li'; // 対象のlist
    $(contents + ':nth-child(n + ' + (show + 1) + ')').addClass('is-hidden');
    if($(contents).length < 11) {
      $('.js-moreBtn').hide();
    }
    $('.js-moreBtn').on('click', function () {
      $(contents + '.is-hidden').slice(0, num).removeClass('is-hidden');
      if ($(contents + '.is-hidden').length == 0) {
        $('.js-moreBtn').fadeOut();
      }
    });

  }
};

export default MoreBtn;
