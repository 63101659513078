/**
 * スマホのナビメニュークラス
 * @constructor
 */
 class Nav {
  constructor() {
  }
  
  /**
   * スマホのナビメニュー
   */
  init() {
    $(function() {
      $('.js-nav').on('click',function(){
        $('.l-nav').toggleClass('is-open');
        $('body').toggleClass('is-fixed');
      });
      $('.l-nav__contents a[href]').on('click', function() {
        $('.l-nav').removeClass('is-open');
        $('body').removeClass('is-fixed');
      });
    });
  }
};

export default Nav;
